.toast.pvda-toast {
  background-color: #504689;
  box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  min-width: 23.5rem;

  .toast-header,
  .toast-body {
    background-color: #504689;
    color: #ffffff;
    border-radius: 0.25rem;
  }

  .toast-header {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    border-bottom: none;
    padding: 1.25rem 1.5rem 0rem;
    display: flex;
    align-items: flex-start;
  }

  .toast-body {
    padding: 0.625rem 1.5rem 1.3125rem;
  }
}

.pvda-toast {
  .toast-header {
    display: flex;
  }

  &__title {
    font-weight: 600;
    font-size: 1rem;
  }

  &__description {
    font-size: 0.75rem;
    font-weight: normal;
  }

  button {
    background: none;
    border: none;
    box-shadow: none;
    outline: none;
  }

  &__btn-close,
  &__btn-okay {
    font-weight: bold;
    color: #ffffff;
  }

  &__btn-close {
    font-size: 1rem;
  }

  &__btn-okay {
    font-size: 0.75rem;
    margin-top: 1.5rem;
    line-height: 160%;
  }
}
