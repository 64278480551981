.pvda-my-feed {
  $this: &;

  &__header {
    min-height: 208px;
    width: 100%;
    padding: 24px;
    background-image: url('../../../assets/images/backgrounds/background-sidebar.png');
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto;
    background-origin: content-box;
    background-color: #443974;
  }

  &__content {
    padding: 30px 24px;

    .pvda-b-tabs {
      border: none;
    }
  }

  &__tab-content {
    max-height: 950px;
    overflow-y: auto;
  }
}
