.pvda-horizontal-progress-bar {
  &__tasks {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 0.5625rem;
    line-height: 0.75rem;
    color: #787587;
  }
}
