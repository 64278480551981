@use 'sass:math';

@keyframes noise-zoom {
  0% {
    transform: scale(1 - math.div(random(5), 200));
  }
  10% {
    transform: scale(1 - math.div(random(2), 200));
  }
  20% {
    transform: scale(1 - math.div(random(2), 200))
      skew(-(random(10)-5) + deg, 0);
  }
  30% {
    transform: scale(1 - math.div(random(2), 200));
  }
  40% {
    transform: scale(1 - math.div(random(2), 200));
  }
  50% {
    transform: scale(1 - math.div(random(2), 200));
  }
  60% {
    transform: scale(1 - math.div(random(2), 200));
  }
  70% {
    transform: scale(1 - math.div(random(2), 200));
  }
  80% {
    transform: scale(1 - math.div(random(2), 200));
    transform: scale(1 - math.div(random(2), 200)) skew(-random(5) + deg, 0);
  }
  90% {
    transform: scale(1 - math.div(random(2), 200));
  }
}

@keyframes noise-anim {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i*math.div(1,$steps))} {
      clip: rect(random(100) + px, 9999px, random(100) + px, 0);
      text-shadow: (random(20)-10 + px)
        (random(20)-10 + px)
        (random(40) + px)
        rgba(255, 255, 255, 0.8);
    }
  }
}

@keyframes noise-anim-2 {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i*math.div(1,$steps))} {
      clip: rect(random(100) + px, 9999px, random(100) + px, 0);
    }
  }
}

@keyframes skeleton {
  to {
    opacity: 0.5;
  }
}
