.pvda-global-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0ecfe;

  &__loader {
    border: 0.725rem solid #f3f3f3;
    border-radius: 50%;
    border-top: 0.725rem solid #2baaa7;
    border-bottom: 0.725rem solid #2baaa7;
    padding: 2rem;
    animation: spin 2s linear infinite;
    width: 13.125rem;
    height: 13.125rem;
  }

  &__logo {
    width: 9.375rem;
    height: 9.375rem;
    position: absolute;
    animation: glowing 1500ms infinite;
  }

  @keyframes glowing {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    75% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
