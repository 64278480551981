.pvda-feed-report-card {
  $this: &;

  position: relative;
  width: 100%;
  padding: 24px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 1rem 0rem;
  overflow-y: hidden;
  transition: all 0.5s;

  &:first-child {
    margin-top: 24px;
  }

  &--expand {
    #{$this}__drop-down-icon {
      transform: rotate(-180deg);
    }
  }

  &__opu {
    font-size: 9px;
    font-weight: normal;
    color: var(--text-color-light);
    line-height: 12px;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: var(--text-color);
  }

  &__name {
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    color: var(--text-color);
  }

  &__description {
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color: var(--text-color);
  }

  &__btn-view-report.btn-custom-primary {
    margin-top: 1rem;
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
  }

  &__updated-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
    font-weight: normal;
    color: var(--text-color-light);

    svg {
      margin-right: 3px;
    }
  }

  &__drop-down-icon {
    cursor: pointer;
    transition: all var(--secs);
  }

  &__status {
    position: absolute;
    top: 13px;
    right: 16px;
  }

  &__progress {
    margin-top: 0.95rem;

    &-header,
    &-body {
      display: flex;
      align-items: center;
    }

    .pvda-horizontal-progress-bar {
      flex: 1;
    }
  }

  &__remarks {
    margin-top: 0.95rem;

    &-header {
      display: flex;
      align-items: center;
    }

    &-body {
      display: flex;
      flex-direction: column;
      background-color: #eeeef4;
      padding: 1rem;
      &__message {
        color: #3f3c4c;
        font-size: 10px;
      }
      .pvda-feed-report-card__stakeholder-info {
        display: flex;
        margin-top: 0.5rem;
      }
    }
  }
}
