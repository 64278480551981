$colors: (
  // Purple / P900
  purple-p1000: #282345,
  // Purple / P900
  purple-p900: #342f5c,
  // Purple / P800
  purple-p800: #463e78,
  // Purple / P500
  purple-p500: #7365b1,
  // Purple / P600
  purple-p600: #6559aa,
  // Purple / P200
  purple-p200: #bdb6ea,
  // Purple / P400
  purple-p400: #8879c9,
  // Purple / P300
  purple-p300: #9f90df,
  // Primary / P700
  primary-p700: #504689,
  // Primary/G500
  primary-g500: #00a19c,
  // Yellow/Y100
  yellow-y100: #feeabd,
  // Yellow/Y200
  yellow-y200: #fedc92,
  // Yellow/Y1200
  yellow-y1200: #e56e18,
  // Yellow/Y900h
  yellow-y900h: #fc930d,
  // Yellow/Y30
  yellow-y30: #fffaef,
  // Emphasis/Danger
  emphasis-danger: #e82121,
  // Emphasis/Link
  emphasis-link: #6559aa,
  // Emphasis/Low N200
  emphasis-low-n200: #e7e7f0,
  // Emphasis/Medium N500
  emphasis-medium-n500: #9f9eae,
  // Emphasis/Medium N400
  emphasis-medium-n400: #c4c3d1,
  // Emphasis/Body N800
  emphasis-body-n800: #3f3c4c,
  // Emphasis/High N600
  emphasis-high-n600: #787587,
  // Emphasis/High N900
  emphasis-high-n900: #2d2b39,
  // Emphasis/Barely N50
  emphasis-barely-n50: #fafafa,
  // Green / G400
  green-g400: #66b2c4,
  // Red / R20
  red-r20: #fff5f7,
  // Red / R50
  red-r50: #fff2f2,
  // Red/R500
  red-r500: #d50000,
  // Blue/R500
  blue-r500: #90a0cd,
  // Blue/B100
  blue-b100: #bcc6e1,
  // Blue/B400
  blue-b400: #415ea9,
  // Neutral/N500
  neutral-n500: #9f9eae,
  // Green/G500
  green-g500: #50a4b9,
  // Green / G21
  green-g21: #e3ecf3,
  // box-shadow
  box-shadow-color: #747474,
  // line border color
  line-border-color: #e7e7f0,
  // Neutral/N100
  neutral-n100: #eeeef4,
  // Neutral/N300
  neutral-n300: #dcdce6,
  // Neutral/N700
  neutral-n700: #625e70,
  // Primary/N0
  primary-n0: #ffffff
);
