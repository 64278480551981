.pvda-search-input {
  $this: &;

  width: 17.5rem;
  height: 2.25rem;
  background: var(--container-color);
  border: 0.0625rem solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem 0.5rem 1rem;

  input {
    font-size: 0.875rem;
    font-weight: normal;
    border: none;
    flex: 1 0 auto;

    &::placeholder {
      font-weight: normal;
      font-size: 0.875rem;
      color: var(--text-color-light);
    }
  }

  &:focus-within {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #007bff40;
  }
}
