.pvda-user-info-avatar {
  $this: &;

  .btn-link {
    text-decoration: none;
  }

  &__popover {
    background-color: var(--container-color);
    border-radius: 20px;
    min-width: 480px;
    box-shadow: 6px 6px 10px #888888;
    z-index: var(--zindex-modal);

    &-header {
      padding: 24px 32px 26px 24px;
      display: flex;
      justify-content: space-between;
    }

    &-body {
      display: flex;
      border-top: 1px solid #e7e7f0;
      padding: 24px;

      #{$this}__button {
        margin-right: 9px;
        color: #6559aa;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          margin-left: 6px;
        }
      }
    }
  }

  &__info {
    color: var(--text-color);

    &-fullName {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 3px;
    }

    &-position {
      font-size: 12px;
      color: var(--text-color);
    }
  }
}
