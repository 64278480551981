:root {
  --height-header: 60px;
  --height-footer: 72px;

  /* Colors */
  --first-color: #342f5c;
  --first-color-light: #463e78;
  --first-color-alt: #504689;
  --title-color: #2d2b39;
  --text-color: #3f3c4c;
  --text-color-light: #9f9eae;
  --text-color-purple: #6559aa;
  --body-color: #e5e5e5;
  --container-color: #ffffff;
  --scroll-bar-color: #dfe1e3;
  --scroll-thumb-color: #dfe1e3;
  --loading-color: #cccccc;
  --red-color: #e82121;
  --green-color: #50a4b9;
  --purple-color: #8879c9;
  --yellow-color: #fac300;

  /* Font and typography */
  --body-font: 'Inter';
  --biggest-font-size: 5rem;
  --bigger-font-size: 3.5rem;
  --big-font-size: 2.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /* Responsive typography */
  @media screen and (min-width: 968px) {
    --biggest-font-size: 7.5rem;
    --bigger-font-size: 4.5rem;
    --big-font-size: 4rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }

  /* Margenes */
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;

  /* used to define z-index */
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-offcanvas: 1050;
  --zindex-modal: 1060;
  --zindex-popover: 1070;
  --zindex-tooltip: 1080;

  /* used to create effects animation */
  --secs: 0.4s;
}
