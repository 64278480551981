.section-title {
  //Section/Title
  font-style: normal;
  font-weight: 600;
  font-size: 0.688rem;
  line-height: 0.825rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.063rem;
}

.section-title-bold {
  /* Section/Title Bold */
  font-weight: 700;
  font-size: 0.6875rem;
  font-style: normal;
  line-height: 0.813rem;
  text-transform: uppercase;
  letter-spacing: 0.063rem;
}

.section-title-medium {
  //Section/Title medium
  font-weight: 500;
  font-size: 0.688rem;
  font-style: normal;
  line-height: 0.688rem;
  text-transform: uppercase;
  letter-spacing: 0.063rem;
}

.title-modal {
  font-style: normal;
  font-weight: 700;
  font-size: 0.688rem;
  line-height: 0.688rem;
  margin-bottom: 0.5rem;
  letter-spacing: 0.063rem;
  text-transform: uppercase;
}

.breadcrumb-medium {
  // Breadcrumb/Medium
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.breadcrumb-semibold {
  //Breadcrumb/SemiBold
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.label-regular {
  //Form / Label Large Regular
  font-style: normal;
  font-weight: 400;
  font-size: 0.938rem;
  line-height: 1.5rem;
  margin-bottom: 0rem;
}

.label-regular-medium {
  //Form / Label Large Regular
  font-style: normal;
  font-weight: 500;
  font-size: 0.813rem;
  line-height: 0.975rem;
}

.label-mini-bold {
  // Form / Label Mini Bold
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.875rem;
}

.body-normal-semibold {
  //styleName: Body/Normal Semibold;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.body-normal {
  //styleName: Body/Normal;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.body-mini-bold {
  //styleName: Body/Mini Bold;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.188rem;
}

.body-mini-medium {
  //styleName: Body/Mini Medium;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.188rem;
}

.body-mini {
  //styleName: Body/Mini Bold;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.188rem;
}

.body-mini-semibold {
  //styleName: Body/Normal Semibold;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
}

.body-micro {
  //styleName: Body/Micro;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.875rem;
}

.body-atomic {
  //styleName: Body / Atomic;
  font-style: normal;
  font-weight: 400;
  font-size: 0.563rem;
  line-height: 0.75rem;
}

.body-micro-medium {
  //styleName: Body/Micro-Medium;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.875rem;
}

.heading-h1-regular {
  /* Heading/H1 Regular */
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem;
}

.heading-h2-medium {
  /* Heading/H2 Medium */
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
}

.heading-h3-medium {
  /* Heading/H3 Medium */
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.heading-h4-regular {
  /* Heading/H4 Regular */
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.heading-h4-medium {
  /* Heading/H4 Medium */
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.heading-h5-regular {
  /* Heading/H5 Regular */
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.heading-h6-regular {
  /* Heading/H6 Regular */
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.heading-h6-medium {
  /* Heading/H6 Medium */
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.button-regular-medium {
  /* Button / Regular Medium */
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.form-description {
  font-style: normal;
  font-weight: normal;
  font-size: 0.688rem;
  line-height: 0.875rem;
}

.table-cell-medium {
  // Table / Cell Medium;
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
}

.table-cell-bold {
  // Table / Cell Bold;
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0.975rem;
}

.button-label--small-medium {
  // Button Label / Small Medium
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
}
