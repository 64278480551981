.pvda-left-sidebar {
  $this: &;

  background: var(--first-color-light);
  width: 60px;
  min-width: 60px;
  transition: width var(--secs), min-width var(--secs);
  z-index: 2;

  &__header {
    background: #2baaa7;
    padding: 14px 0px 14px 17px;
    position: relative;
    cursor: pointer;
  }

  &__logo {
    overflow-x: hidden;
    white-space: nowrap;
  }

  &__logo-mark {
    margin-right: 2px;
  }

  &__word-mark {
    opacity: 0;
    display: none;
    transition: all var(--secs);
  }

  &__btn-expand {
    background: var(--container-color);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    width: 24px;
    height: 24px;
    position: absolute;
    right: -12px;
    bottom: -12px;
    z-index: var(--zindex-fixed);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-180deg);
    transition: transform var(--secs);
    cursor: pointer;
  }

  &__content {
    white-space: nowrap;
  }

  &--expand {
    min-width: 240px;
    width: 240px;

    #{$this}__word-mark {
      opacity: 1;
      display: inline-block;
    }

    #{$this}__btn-expand {
      transform: rotate(0deg);
    }
  }
}
