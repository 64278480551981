$padding-tab: 8px;
$tab-height-mobile: 40px;
$padding-tab-mobile: 6px;

.sidebar-menu__sub-menus {
  margin-top: 0.5rem;

  .sidebar-menu__sub-menu {
    padding: $padding-tab 0 $padding-tab;
  }

  .sub-menu-content {
    display: flex;
    justify-content: center;
    position: relative;

    &:hover {
      .sub-menu__tooltip {
        display: block;
        transition: all 0.5s ease;
        top: 50%;
        opacity: 1;
        left: 120px;
      }
    }

    .pvda-opu-menu__item-content {
      margin-left: 0;
    }
    .sub-menu-title {
      margin-left: 1rem;
      font-weight: bold;
      font-size: 12px;
    }
    .sub-menu__tooltip {
      position: absolute;
      left: 122px;
      top: 0;
      transform: translate(-50%, -50%);
      border-radius: 6px;
      height: 32px;
      min-width: 122px;
      line-height: 32px;
      background: #ffffff;
      color: var(--text-color);
      text-align: center;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      transition: 0s;
      pointer-events: none;
      display: none;
      margin: 0 0.5rem;
      padding: 0 0.5rem;
      z-index: var(--zindex-tooltip);
    }
  }
}
