.pvda-my-recent {
  $this: &;

  .pvda-b-tabs {
    border: none;
  }

  &__header {
    width: 100%;
    padding: 24px 24px 0px;
    background-color: #ffffff;
    box-shadow: -1px 0px 0px #dce0e9;
  }

  &__content {
    padding: 18px 24px;
  }
}
