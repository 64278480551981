.pvda-opu-menu {
  $this: &;

  background: var(--first-color);
  color: #ffffff;

  &__header {
    padding: 17.5px 0px 25.5px 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    color: #ffffff;

    svg {
      min-width: 1.5rem;
    }

    span {
      display: none;
      margin-left: 18px;
    }
  }

  &__body {
    padding-bottom: 1.5rem;
  }

  &__tooltip {
    position: absolute;
    left: 122px;
    top: 0;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    height: 32px;
    min-width: 122px;
    line-height: 32px;
    background: #ffffff;
    color: var(--text-color);
    text-align: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: 0s;
    opacity: 0;
    pointer-events: none;
    display: block;
    margin: 0 0.5rem;
    padding: 0 0.5rem;
    z-index: var(--zindex-tooltip);
  }

  &__item {
    position: relative;
    padding: 0.5rem 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:first-child {
      padding-top: 0px;
    }

    &:hover {
      #{$this}__tooltip {
        transition: all 0.5s ease;
        top: 50%;
        opacity: 1;
      }
    }
  }

  &__item-content {
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &--activated {
      background: linear-gradient(180deg, #554d94 0%, rgba(52, 47, 92, 0) 50%),
        radial-gradient(50% 50% at 50% 95.83%, #69639a 34.9%, #342f5c 100%);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
      border-radius: 50%;
    }
  }

  &__item-text,
  .sub-menu-title {
    display: none;
  }

  &__item-notifi {
    background: #e82121;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    font-size: 9px;
  }

  &__item-btn {
    position: relative;
    padding-top: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width var(--secs), min-width var(--secs);

    button {
      min-width: 40px;
      width: 40px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 1rem;

      span {
        display: none;
      }
    }

    &:hover {
      #{$this}__tooltip {
        transition: all 0.5s ease;
        top: 60%;
        left: 147px;
        opacity: 1;
      }
    }
  }
  &__icon-dropdown {
    display: none;
  }

  &--expand {
    .pvda-opu-menu__body {
      margin-left: 2rem;
      border-left: 1px solid #8879c9;
    }
    span,
    .sub-menu-title {
      display: inline-block;
    }

    #{$this}__tooltip,
    .sub-menu__tooltip {
      display: none;
    }

    #{$this}__item {
      margin-right: 11px;
      line-height: 160%;
      padding: 0.5rem 0px;
      font-weight: 500;
      font-size: 12px;
      flex-direction: column;
      align-items: center;

      &:first-child {
        padding-top: 0px;
      }
    }

    #{$this}__icon-dropdown {
      display: block;
    }

    #{$this}__item-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #{$this}__item-text {
      padding: 6px 12px 7px 14px;
    }

    #{$this}__item-content {
      width: 100%;
      margin-left: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    #{$this}__item-content:hover,
    #{$this}__item-content--activated {
      background: #282345;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
      border-radius: 0.5rem 25px 25px 0.5rem;

      #{$this}__item-icon {
        background: linear-gradient(180deg, #554d94 0%, rgba(52, 47, 92, 0) 50%),
          radial-gradient(50% 50% at 50% 95.83%, #69639a 34.9%, #342f5c 100%);
      }

      #{$this}__item-text {
        font-weight: bold;
      }
    }

    #{$this}__item-btn {
      padding-top: 0.5rem;
      margin-left: 30px;
      margin-right: 1.5rem;
      border-left: 1px solid #8879c9;

      button {
        margin-left: 1rem;
        min-width: 60px;
        width: 100%;

        span {
          display: inline-block;
        }
      }
    }
    .sidebar-menu__sub-menus {
      margin-left: 1rem;
      .sidebar-menu__sub-menu {
        margin-left: 1rem;
        border-left: 1px solid #8879c9;
        &:hover {
          .sub-menu__tooltip {
            display: none;
          }
        }
      }
    }
  }
}
