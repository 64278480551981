@import '../commons/traffic-color.scss';
@import '../commons/recharts.scss';

.epic-modal-chart {
  & &__dialog {
    max-width: 70%;
  }

  .epic-filter-chart {
    width: auto;
    max-width: none;
    border: none;

    &__header {
      display: none;
    }

    &__body {
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      .pvda-check__label {
        width: auto;
      }
      .pvda-check {
        width: auto;
      }
    }

    &__amount-check {
      display: none;
    }
  }

  .epic-chart {
    &__header {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &__container {
      margin-top: 5rem;
    }

    &__options {
      display: none;
    }
  }

  .title {
    position: absolute;
  }

  .modal-content {
    padding: 1.5rem;
  }

  .modal-header {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    border: none;
    .btn-close {
      padding: 0;
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  .line-chart {
    .epic-filter-chart__rectangle {
      height: 0.125rem;
    }
  }
}
