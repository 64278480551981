.pvda-main {
  position: relative;
  flex-grow: 1;
  background-image: url('assets/images/backgrounds/background-main.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f1f4fb;
  overflow: hidden auto;

  .pvda-main__body {
    overflow: hidden;
    padding: 0px 48px;
    min-height: calc(100vh - var(--height-header));
  }
}
