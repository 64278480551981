.pvda-recent-report-card {
  $this: &;

  position: relative;
  width: 100%;
  padding: 24px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 1rem 0rem;

  &__opu {
    font-size: 9px;
    font-weight: normal;
    color: var(--text-color-light);
    margin-bottom: 4px;
  }

  &__name {
    font-size: 12px;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 0.5rem;
    padding-right: 0.5rem;
  }

  &__category-type {
    font-weight: normal;
    font-size: 12px;
    color: var(--text-color);
    margin-bottom: 6px;
  }

  &__updated-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
    font-weight: normal;
    color: var(--text-color-light);

    svg {
      margin-right: 3px;
    }
  }

  &__favourites {
    position: absolute;
    top: 13px;
    right: 16px;
  }
}
