.pvda-view-performance-analysis-loading {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
    box-shadow: 0px 0px 1px #747474;
    padding: 1.375rem 1.5rem 1.5rem 1.5rem;
  }
}
