.pvda-recently-published-report-loading-item {
  margin-bottom: 16px;
  padding: 24px;
  background: var(--container-color);
  box-shadow: 0px 0px 1px #747474;
  border-radius: 8px;
  animation: skeleton 1s ease infinite alternate;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__type {
    height: 14px;
    width: 80px;
  }

  &__name {
    margin-top: 4px;
    height: 20px;
    width: 333px;
  }

  &__published-date {
    margin-top: 8px;
    width: 100px;
    height: 14px;
  }

  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--loading-color);
    margin-left: 60px;
  }

  &__report-duration-title {
    width: 75px;
    height: 14px;
  }

  &__report-duration-value {
    margin-top: 4px;
    height: 19px;
    width: 145px;
  }

  &__published-date,
  &__name,
  &__type,
  &__report-duration-title,
  &__report-duration-value {
    background-color: var(--loading-color);
    border-radius: 8px;
  }
}
