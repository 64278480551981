.pvda-functions-kpi-loading {
  &__card {
    margin-top: 1rem;
    padding: 1.5rem;
    background: var(--container-color);
    box-shadow: 0px 0px 1px #747474;
    filter: drop-shadow(0px 0px 1px #747474);
    border-radius: 8px;
  }
}
