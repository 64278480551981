/* Custom theme */
$theme-colors: (
  'custom-primary': #504689,
  'custom-secondary': #ffffff,
  'custom-danger': #e82121,
  'custom-no-border': #ffffff,
  'horizontal-progress-bar': #8879c9,
);

/* Custom classes */
.btn {
  &:disabled,
  &.disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }

  &.btn-custom-no-border {
    background-color: transparent;
    border-color: transparent;
    color: #504689;
    transition: none;
    border: none;

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:focus-visible,
    &:target,
    &:disabled,
    &:focus-within {
      background-color: transparent;
      border-color: transparent;
      color: #504689;
      box-shadow: none;
      border: none;
      border-radius: 0%;
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: all;
    }
  }
}

.pvda-b-progressbar {
  &.progress {
    height: 0.5rem;
    border-radius: inherit;

    .progress-bar {
      background-color: #2baaa7;
      animation: progressbar 2s infinite;
    }
  }

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--zindex-fixed);
  }

  @keyframes progressbar {
    0% {
      width: 0px;
    }
    25% {
      width: 25%;
    }
    50% {
      width: 50%;
    }
    75% {
      width: 75%;
    }
    100% {
      width: 100%;
    }
  }
}

.pvda-b-tabs,
.pvda-custom-b-tabs {
  border: none;

  .nav-item {
    display: block;
    margin-right: 1.5rem;

    .nav-link {
      padding: 0px 0px 16px 0px;
      border: none;
      font-style: normal;
      font-weight: normal;
      line-height: 20px;
      color: var(--text-color-light);
    }

    .nav-link.active {
      font-weight: 600;
      color: var(--text-color);
      border-bottom: 2px solid #50a4b9;
    }
  }
}

.placeholder {
  border-radius: 8px;
}
