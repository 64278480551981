// Fonts
@import './fonts/index.scss';
@import './variables/index.scss';
@import './variables/color.scss';
@import './animation/index.scss';
@import './commons/index.scss';
@import './mixin/index.scss';

@import './custom/custom-bootstrap.scss';

// Styles as className
@each $name, $value in $colors {
  .#{$name} {
    color: $value;
  }
}

// Default styles bootstrap
@import '~bootstrap/scss/bootstrap';
// Other Libs Styles
@import '~@draft-js-plugins/mention/lib/plugin.css';
@import '@draft-js-plugins/emoji/lib/plugin.css';
@import '~react-datepicker/dist/react-datepicker';
@import 'swiper/css';
