.pvda-kpi-detail-loading {
  &__header,
  &__body {
    margin-top: 1.5rem;
  }

  &__body {
    display: flex;
    gap: 1.5rem;

    &-left {
      width: 75%;
    }

    &-right {
      width: 25%;
    }
  }
}
