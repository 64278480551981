.pvda-traffic-color {
  width: 6px;
  height: 6px;
  border-radius: 50%;

  &__default {
    background: #e7e7f0;
  }

  &__green {
    background: #4caf50;
  }

  &__red {
    background: #e82121;
  }

  &__yellow {
    background: #fac300;
  }
}
