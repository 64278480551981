.pvda-avatar {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: var(--container-color);
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #00a19c;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;

  &--circle {
    border-radius: 50%;
  }

  &--square {
    border-radius: 0.125rem;
  }

  &--icon {
    font-size: 1rem;
  }

  &--image {
    background: 0 0;

    img {
      display: block;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  &--string {
    -webkit-transform-origin: 0 center;
    transform-origin: 0 center;
  }

  &--lg {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    font-size: 1rem;
  }

  &--sm {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 0.625rem;
  }
}
