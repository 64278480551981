.pvda-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0.75rem 3rem;

  &__logo {
    flex: 1;

    span {
      margin-left: 24px;
    }
  }

  &__search {
    .pvda-search-input {
      border-radius: 60px;

      svg {
        color: var(--text-color) !important;
      }
    }
  }
}
